import React, { useEffect, useState, useMemo } from "react"
import { Link, Navigate, useParams, useOutletContext } from "react-router-dom"
import { startCase } from "lodash"
import moment from "moment"

import useDataFetch from "~/hooks/useDataFetch"
import formatCurrency from "~/utils/formatCurrency"

import DisplayField from "~/components/DisplayField"

const Booked = () => {
  const { accountId, appointmentId } = useParams()
  const endpoint = `/accounts/${accountId}`
  const { appointment, setAppointment, loaded: isAppointmentLoaded } = useDataFetch([], [ endpoint, "appointments", appointmentId ].join("/"), "appointment")

  if(!isAppointmentLoaded) return null
  console.log(appointment)
  return (
    <div>
      <p>
        Thanks! Your appointment booking request has been submitted!
      </p>

      <div className="row">
        <div className="col">
          <DisplayField label="Booking Status" value={startCase(appointment.status)} />
          <DisplayField label="Appointment Date" value={moment(appointment.start_time).format("MMM DD YYYY")} />
          <DisplayField label="Appointment Time" value={moment(appointment.start_time).format("h:mma")} />
        </div>
        <div className="col">
          { appointment.services.length > 0 &&
            <>
              <h5>Services</h5>
              <p>{ appointment.services[0].name }</p>
            </>
          }
          { appointment.add_ons.length > 0 &&
            <>
              <h5>Add Ons</h5>
              { appointment.add_ons.map((addOn) => <p>{ addOn.name }</p>)}
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default Booked

