import React, { useEffect, useState, useMemo } from "react"
import { Link, Navigate, useParams, useOutletContext } from "react-router-dom"
import { isValidPhoneNumber } from 'react-phone-number-input'

import useDataFetch from "~/hooks/useDataFetch"
import useForm from "~/hooks/useForm"
import request from "~/lib/request"

import TextInput from "~/components/TextInput"
import PhoneInput from 'react-phone-number-input/input'

const ClientInfo = () => {
  const { accountId } = useParams()
  const [clientInfo, setClientInfo, onChange] = useForm({})
  const [isPhoneChecked, setIsPhoneChecked] = useState(false)
  const [isPhoneValid, setIsPhoneValid] = useState(false)
  const [appointmentId, setAppointmentId] = useState(null)

  const updatePhone = (phone) => {
    const stagedInfo = clientInfo
    stagedInfo.phone = phone
    setClientInfo(stagedInfo)
  }

  const checkNumber = () => {
    const phoneIsValid = isValidPhoneNumber(clientInfo.phone)
    setIsPhoneValid(phoneIsValid)
    if(!phoneIsValid) return false

    const req = request.post(`/accounts/${accountId}/clients/info`, { phone: clientInfo.phone })
    req.then((data) => {
      setIsPhoneChecked(true)
      const { id, name, email } = data
      const stagedInfo = clientInfo
      stagedInfo.id = id
      stagedInfo.name = name
      stagedInfo.email = email

      setClientInfo(stagedInfo)
    })
  }

  const canSubmit = useMemo(() => {
    return clientInfo.name && clientInfo.email && clientInfo.phone
  }, [ clientInfo.name, clientInfo.email, clientInfo.phone ])

  const submitClient = () => {
    const clientReq = request.put(`/accounts/${accountId}/clients/${clientInfo.id}`, { client: clientInfo })
    clientReq.then((data) => {
      const appointmentReq = request.post(`/accounts/${accountId}/appointments`, { appointment: { client_id: data.id }})
      appointmentReq.then((appointment) => {
        console.log(appointment)
        setAppointmentId(appointment.id)
      })
    })
  }

  if(appointmentId) return <Navigate to={appointmentId} />
  return (
    <div>
      <div className="d-flex">
        <div className="w-50 p-4 me-2 border-end">
          <p>
            Welcome to my appointment booking form. I'm so glad you're here! I understand that booking a massage can be a big step in taking care of yourself, and I want you to know that I'm here to support you every step of the way. My goal is to create a safe and welcoming environment where you can relax and let go of any tension or stress. I look forward to working with you and helping you achieve your wellness goals.
          </p>
        </div>
        <div className="w-50 p-4">
          <div className="mb-4">
            <label className="form-label">Phone Number</label>
              <PhoneInput
                name="phone"
                country="US"
                value={clientInfo.phone}
                onChange={updatePhone}
                onBlur={checkNumber}
                className="form-control"
              />
          </div>
          <TextInput
            label="Your Name"
            name="name"
            value={clientInfo.name}
            onChange={onChange}
            disabled={!isPhoneChecked}
          />
          <TextInput
            label="Your Email"
            name="email"
            value={clientInfo.email}
            onChange={onChange}
            disabled={!isPhoneChecked}
          />

          <div className="d-flex">
            <button
              className="btn btn-primary ms-auto"
              disabled={!canSubmit}
              onClick={submitClient}
            >
              Select Date & Time
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientInfo
