import React from "react";
import { Route, createRoutesFromElements, createBrowserRouter } from "react-router-dom";

import Layout from "~/views/ClientLayout"

import AppointmentWindow from "~/views/Booking/AppointmentWindow"
import ClientInfo from "~/views/Booking/ClientInfo"
import ServicesSelect from "~/views/Booking/ServicesSelect"
import Booked from "~/views/Booking/Booked"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/:accountId" element={<Layout/>}>
      <Route index element={<ClientInfo />} />
      <Route path=":appointmentId" element={<AppointmentWindow />} />
      <Route path=":appointmentId/:date/:time" element={<ServicesSelect />} />
      <Route path=":appointmentId/booked" element={<Booked />} />
    </Route>
  )
);

export default router
