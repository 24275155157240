import React, { useState, useEffect, useMemo } from "react"
import moment from "moment"

import Icon from "~/components/Icon"

const Calendar = ({value, onChange}) => {
  const FORMAT = "YYYY-MM-DD"
  const [ currentMonth, setCurrentMonth ] = useState(moment().format(FORMAT))
  const today = moment()
  const [todayDay, todayMonth, todayYear] = [today.day(), today.month(), today.year()]

  const navigateMonth = (direction) => {
    const date = moment(currentMonth)
    setCurrentMonth(date.add(direction, "month").format(FORMAT))
  }

  const days = [ 0, 1, 2, 3, 4, 5, 6 ]

  const calendarMap = useMemo(() => {
    const startOfMonth = moment(currentMonth).startOf("month")
    const endOfMonth = moment(currentMonth).endOf("month")
    let weekCount = endOfMonth.isoWeek() - startOfMonth.isoWeek() + 1

    const map = []

    const workingDate = startOfMonth.clone()
    while(workingDate.isSameOrBefore(endOfMonth)) {
      let weekMap = []

      days.map((dayOfWeek) => {
        if(workingDate.day() == dayOfWeek && workingDate.isSameOrBefore(endOfMonth)) {
          weekMap.push(workingDate.format("D"))
          workingDate.add(1, "day")
        } else {
          weekMap.push(null)
        }
      })

      map.push(weekMap)
    }

    return map
  }, [currentMonth])

  return(
    <div>
      <div className="d-flex align-items-center mb-4">
        <span className="btn btn-primary rounded-circle py-3 px-4" onClick={() => navigateMonth(-1)}>
          <Icon name="chevron-left" className="p-0" />
        </span>
        <h2 className="flex-grow-1 text-center">{ moment(currentMonth).format("MMMM YYYY") }</h2>
        <span className="btn btn-primary rounded-circle py-3 px-4" onClick={() => navigateMonth(1)}>
          <Icon name="chevron-right" className="p-0" />
        </span>
      </div>
      <div className="d-flex flex-column">
        <div key="header" className="w-100 d-flex align-items-center">
          { days.map((d, di) => {
            return(
              <div key={di} className="text-center" style={{width: (100/7) + "%"}} >
                { moment().day(d).format("dd") }
              </div>
            )
          })}
        </div>
        { calendarMap.map((week, wi) => {
          return(
            <div key={wi} className="w-100 d-flex align-items-center">
              { week.map((day, di) => {
                let date = moment(currentMonth).date(day)
                const isSelected = moment(value).isSame(date)
                const baseClasses = "p-4 m-1 text-center cursor-pointer lh-1 rounded-circle"
                return(
                  <div
                    key={di}
                    className={
                      `
                        ${baseClasses}
                        ${isSelected ? "bg-info text-white fw-boldest" : "bg-hover-light-info"}
                        ${moment().isSame(date, "day") && "border border-info"}
                      `
                    }
                    onClick={() => onChange(date.format(FORMAT))}
                    style={{width: (100/7) + "%"}}
                  >
                    {day ? day : <>&nbsp;</>}
                  </div>
                )
              }
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Calendar



