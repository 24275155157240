import React, { useState } from "react"
import { Outlet, useParams } from "react-router-dom";

import useDataFetch from "~/hooks/useDataFetch"

import Sidebar from "~/components/Sidebar"

const Layout = () => {
  const [ accountName, setAccountName ] = useState()

  const { accountId } = useParams()
  const endpoint = `/accounts/${accountId}`

  const { account, setAccount, loaded } = useDataFetch([], endpoint, "account")

  return(
    <div className="d-flex flex-column flex-root" style={{backgroundColor: "#f8f6f2"}}>
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        { account &&
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            <h1 className="title">{ account.name }</h1>
            <h4 className="subtitle mb-12">on Upbooka</h4>
            <div className="w-lg-75 bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
              <Outlet />
            </div>
          </div>
        }
        <div className="d-flex flex-center flex-column-auto p-10">
          <p>Upbooka - Built in PDX with 🌲🥃☕️</p>
        </div>
      </div>
    </div>
  )
}
export default Layout
