import React, { useEffect, useState } from "react"
import { Link, Navigate, useParams, useOutletContext } from "react-router-dom"
import moment from "moment"
import request from "~/lib/request"

import Icon from "~/components/Icon"
import Header from "~/components/Header"
import Table from "~/components/Table"
import Calendar from "~/components/Calendar"

import useDataFetch from "~/hooks/useDataFetch"
import formatCurrency from "~/utils/formatCurrency"

const Landing = () => {
  const { accountId, appointmentId } = useParams()
  const accountEndpoint = `/accounts/${accountId}`
  const appointmentEndpoint = [accountEndpoint, `appointments/${appointmentId}`].join("/")
  const { account, setAccount, loaded } = useDataFetch([], accountEndpoint, "account")
  const { appointment, setAppointment, loaded: appointmentLoaded } = useDataFetch([], appointmentEndpoint, "appointment")

  const [ selectedDate, setSelectedDate ] = useState(null)
  const [ selectedTime, setSelectedTime ] = useState(null)
  const [ savedWindow, setSavedWindow ] = useState(null)

  useEffect(() =>{ setSelectedTime(null)}, [ selectedDate ])
  useEffect(() => {
    if (appointment.start_time) {
      const apptTime = moment(appointment.start_time)
      setSelectedDate(apptTime.format("YYYY-MM-DD"))
      setSelectedTime(apptTime.format("HH"))
    }
  }, [ appointment ])

  const submitAppointmentTime = () => {
    const data = {
      start_time: moment([selectedDate, selectedTime].join(" ")).format("YYYY-MM-DD HH:mm")
    }
    const req = request.put(appointmentEndpoint, { appointment: data })
    req.then((data) => {
      if(data.start_time) {
        setSavedWindow(moment(data.start_time).format("YYYY-MM-DD/HH:mm"))
      }
    })
  }

  if (!loaded) return null

  const availability = () => account.availability[moment(selectedDate).format("ddd")]

  if (savedWindow) return <Navigate to={savedWindow} />
  return(
    <div>
      <div className="d-flex">
        <div className="pe-4 border-end">
          <h3 className="text-center">Select Appointment Date</h3>
          <Calendar onChange={setSelectedDate} value={selectedDate} />
        </div>
        <div className="ps-4 w-100">
          { !selectedDate &&
            <p className="text-center mt-4">Select a date to see available times</p>
          }
          { selectedDate &&
            <>
              <h3>Select Appointment Time</h3>
              <div className="w-100">
                { availability().map((hour, hi) => {
                  const time = moment().hour(hour).minute("00")
                  return(
                    <div
                      key={hi}
                      className={`
                        d-inline-block px-2 py-1 rounded m-2 text-center cursor-pointer
                        ${ selectedTime == hour ? "bg-info text-white fw-bolder" : "border bg-hover-light-info" }
                      `}
                      onClick={() => setSelectedTime(hour) }
                      style={{width: "20%"}}
                    >
                      { time.format("h:mma") }
                    </div>
                  )
                })}
                { !availability() &&
                  <p>No appointment times</p>
                }

              </div>

            </>
          }
        </div>
      </div>
      <div className="d-flex">
        <div className="ms-auto">
          <button disabled={ !selectedTime && !selectedDate} onClick={submitAppointmentTime} className="btn btn-primary">
              Select Services
          </button>
        </div>
      </div>
    </div>
  )
}

export default Landing
