import React from "react";
import { useOutletContext } from "react-router-dom"
import useDataFetch from "~/hooks/useDataFetch"

const DisplayField = ({
  label,
  value,
  className,
}) => {
  return(
    <div className="row mb-4">
      <label className="col fw-bold text-muted">{ label }</label>
      <div className="col">
        <span className="fw-bolder fs-6 text-dark">{ value }</span>
      </div>
    </div>
  )
}

export default DisplayField

