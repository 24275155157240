import React, { useEffect, useState, useMemo } from "react"
import { Link, Navigate, useParams, useOutletContext } from "react-router-dom"
import { xor } from "lodash"
import request from "~/lib/request"

import Icon from "~/components/Icon"
import TextArea from "~/components/TextArea"

import useDataFetch from "~/hooks/useDataFetch"
import formatCurrency from "~/utils/formatCurrency"

const ServicesSelect = () => {
  const { accountId, appointmentId } = useParams()
  const endpoint = `/accounts/${accountId}`
  const { services, setServices, loaded: servicesLoaded } = useDataFetch([], [ endpoint, "services" ].join("/"), "services")
  const { addOns, setAddOns, loaded: addOnsLoaded } = useDataFetch([], [ endpoint, "add_ons" ].join("/"), "addOns")

  const [ selectedService, setSelectedService ] = useState(null)
  const [ selectedAddOns, setSelectedAddOns ] = useState([])
  const [ appointmentNotes, setAppointmentNotes ] = useState("")
  const [ isBooked, setIsBooked ] = useState(false)

  const toggleAddOn = (addOnId) => setSelectedAddOns(xor(selectedAddOns, [addOnId]))

  const appointmentTotal = useMemo(() => {
    if(!servicesLoaded && !addOnsLoaded) return null

    const servicePrice = services.filter((s) => s.id == selectedService)[0]?.price_cents
    const addOnPrices = addOns.map((a) => selectedAddOns.indexOf(a.id) > -1 ? a.price_cents : 0 )

    return [ servicePrice, addOnPrices ].flat().reduce((sum, price) => sum + price, 0)
  }, [ selectedAddOns, selectedService ])

  const bookAppointment = () => {
    const lineItems = [ selectedService, ...selectedAddOns ]
    const req = request.post(`/accounts/${accountId}/appointments/${appointmentId}/book`, { lineItems })
    req.then((data) => {
      if(data.booked) {
        setIsBooked(true)
      }
    })
  }

  if(!servicesLoaded && !addOnsLoaded) return null
  if(isBooked) return <Navigate to={`/${accountId}/${appointmentId}/booked`} />
  return(
    <div>
      <div className="d-flex">
        <div className="flex-grow-1">
          <h3>Select your appointment service</h3>
          <table className="table">
            <tbody>
              { services.map((service, si) => {
                return(
                  <tr key={si} className="cursor pointer bg-hover-light-info" onClick={() => setSelectedService(service.id)}>
                    <td className="p-2"><Icon size={2} name={selectedService == service.id ? "check-circle-fill" : "circle"} /></td>
                    <td className="p-2">{ formatCurrency(service.price_cents) }</td>
                    <td className="p-2 fw-bolder w-100">{ service.name }</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="flex-grow-1">
          <h3>Select your appointment add ons</h3>
          <table className="table">
            <tbody>
              { addOns.map((addOn, si) => {
                return(
                  <tr key={si} className="cursor pointer bg-hover-light-info" onClick={() => toggleAddOn(addOn.id)}>
                    <td className="p-2"><Icon size={2} name={selectedAddOns.indexOf(addOn.id) > -1 ? "check-square-fill" : "square"} /></td>
                    <td className="p-2">{ formatCurrency(addOn.price_cents) }</td>
                    <td className="p-2 fw-bolder w-100">{ addOn.name }</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <h6>Notes/Requests</h6>

        <TextArea
          value={appointmentNotes}
          onChange={setAppointmentNotes}
          placeholder="Anything you'd like to communicate about this appointment?"
        />

      </div>
      <div className="d-flex">
        <p className="ms-auto">
          Estimated Appointment Total: { formatCurrency(appointmentTotal || 0) }
        </p>
      </div>
      <div className="d-flex justify-content-between">
        <Link to={`/${accountId}/${appointmentId}`} className="btn btn-primary">
          <Icon name="chevron-left" className="me-2"/>
          Back
        </Link>
        <span className={`btn ${selectedService ? "btn-primary" : "btn-light"}`} onClick={bookAppointment}>
          <Icon name="check" className="ms-2 is-bolder"/>
          Submit Booking
        </span>
      </div>
    </div>
  )
}

export default ServicesSelect

